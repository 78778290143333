import React, { useState, useEffect } from "react"
import styles from "./share.module.scss"
import Layout from "../Layout"
import SvgIcon from "../SvgIcon"
import BudText from "../TextBudStage/"
import { IsMobileDevice } from "../../../utils"

const Share = () => {
  const [isMobile, setIsMobile] = useState()
  const [coverId, setCoverId] = useState("")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    const { search } = window.location
    const id = search.split("=")[1] || []
    setCoverId(id)
  }, [])

  const handleShare = () => {
    navigator.share({
      title: "Budstage - El mañana en tus manos",
      text: `Budweseiser te invita a que votes por tu tema favorito`,
      url: `https://budweiser.com.ar/budstage/tema/?id=${coverId}`,
    })
  }

  return (
    <div className={styles.sharePop}>
      <Layout noFooter type="secondary">
        <div className={styles.container}>
          {!isMobile && (
            <>
              <SvgIcon
                className={styles.canIcon}
                size="5.636vw"
                name="can-icon"
              />
              <SvgIcon
                className={styles.peaceIcon}
                size="2.695vw"
                name="peace-icon"
              />
              <SvgIcon
                className={styles.starIcon}
                size="7.281vw"
                name="star-icon"
              />
              <SvgIcon
                className={styles.lightningIcon}
                size="3.472vw"
                name="lightning"
              />
              <SvgIcon
                className={styles.discIcon}
                size="10.417vw"
                name="disc-icon"
              />
              <BudText orientation="vertical" className={styles.budText} />
            </>
          )}
          <SvgIcon className={styles.textura} name="textura-fondo" />

          <div className={styles.share}>
            <p>#BudStage</p>
            <p>COMPARTÍ TU TEMA</p>
            <p>
              Hagamos que la música siga sonando en todos lados. <br />{" "}
              ¡Compartí el cover en tus redes para tener más chances de ganar!
            </p>
            <div className={styles.links}>
              <a
                href={`https://api.whatsapp.com/send?text=%F0%9F%98%84Budweiser%20te%20invita%20a%20que%20votes%20por%20tu%20tema%20favorito%F0%9F%8F%86%F0%9F%8F%86%20%0A%20https://budweiser.com.ar/budstage/tema/?id=${coverId}`}
                target="_blank"
              >
                <SvgIcon
                  name="wp-icon"
                  className={styles.wpIcon}
                  size="1.111vw"
                />
                <p>COMPARTIR EN WHATSAPP</p>
              </a>
              <a
                href={`http://www.facebook.com/sharer.php?u=https://budweiser.com.ar/budstage/tema/?id=${coverId}`}
                target="_blank"
              >
                <SvgIcon
                  name="fb-icon"
                  className={styles.fbIcon}
                  style={{ paddingRight: "2%" }}
                  size="0.666vw"
                />
                <p>COMPARTIR EN FACEBOOK</p>
              </a>
              {isMobile ? (
                <div onClick={handleShare} className={styles.instagram}>
                  <SvgIcon
                    name="instagram-icon"
                    className={styles.fbIcon}
                    size="1.111vw"
                  />
                  <p>COMPARTIR EN INSTAGRAM</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Share
